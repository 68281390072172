import axios_init from "./axios_init"

export const requests = {
  Auth: {
    login: (data) => axios_init.post(`/seller/auth/login`, data),
  },
  Element: {
    categories: (data) => axios_init.post(`/seller/element/categories`, data),
    countries: (data) => axios_init.post(`/seller/element/countries`, data),
    warehouses: () => axios_init.post(`/seller/element/warehouses`),
    units: () => axios_init.post(`/seller/element/units`),
    attributes: (data) =>
      axios_init.post(`/seller/element/categories/show`, data),
    getList: (data) => axios_init.post(`/seller/element/index`, data),
    show: (data) => axios_init.post(`/seller/element/show`, data),
    statuses: (data) => axios_init.post(`/seller/element/statuses`, data),

    create: (data) => axios_init.post(`/seller/element/create`, data),
    update: (data) => axios_init.post(`/seller/element/update`, data),
    delete: (data) => axios_init.post(`/seller/element/remove`, data),
    restore: (data) => axios_init.post(`/seller/element/restore`, data),
  },

  Uploads: {
    create: (data) => axios_init.post(`/upload/create`, data),
  },
  DeliveryMethod: {
    getList: (data) => axios_init.post(`/admin/company/delivery-methods`, data),
  },
  Product: {
    create: (data) => axios_init.post("/seller/element/create", data),
  },

  Warehouse: {
    getList: (data) => axios_init.post(`/seller/warehouse/index`, data),
    create: (data) => axios_init.post(`/seller/warehouse/create`, data),
    update: (data) => axios_init.post(`/seller/warehouse/update`, data),
    delete: (data) => axios_init.post(`/seller/warehouse/remove`, data),
    restore: (data) => axios_init.post(`/seller/warehouse/restore`, data),
    show: (data) => axios_init.post(`/seller/warehouse/show`, data),
  },
  Order: {
    getList: (data) => axios_init.post(`/seller/order/index`, data),
    statusUpdate: (data) => axios_init.post(`/seller/order/update`, data),
    show: (data) => axios_init.post(`/seller/order/show`, data),
  },
  Rfp: {
    getList: (data) => axios_init.post(`/seller/rfp/index`, data),
    statusUpdate: (data) => axios_init.post(`/seller/rfp/update`, data),
    show: (data) => axios_init.post(`/seller/rfp/show`, data),
  },
  Cities: {
    city: (data) => axios_init.post(`/cities`, data),
  },
  Language: {
    index: (data) => axios_init.post(`/language`, data),
  },
  User: {
    userMe: (data) => axios_init.post(`/seller/user/me`, data),
    update: (data) => axios_init.post(`/seller/user/update`, data),
  },
  Contract: {
    getList: (data) => axios_init.post(`/seller/contract/index`, data),
    create: (data) => axios_init.post(`/seller/contract/create`, data),
    update: (data) => axios_init.post(`/seller/contract/update`, data),
    delete: (data) => axios_init.post(`/seller/contract/remove`, data),
    restore: (data) => axios_init.post(`/seller/contract/restore`, data),
  },
  Staff: {
    getList: (data) => axios_init.post(`/seller/staff/index`, data),
    create: (data) => axios_init.post(`/seller/staff/create`, data),
    update: (data) => axios_init.post(`/seller/staff/update`, data),
    delete: (data) => axios_init.post(`/seller/staff/remove`, data),
    restore: (data) => axios_init.post(`/seller/staff/restore`, data),
    show: (data) => axios_init.post(`/seller/staff/show`, data),
  },
  Couriers: {
    getList: (data) => axios_init.post(`/seller/courier/index`, data),
    create: (data) => axios_init.post(`/seller/courier/create`, data),
    update: (data) => axios_init.post(`/seller/courier/update`, data),
    delete: (data) => axios_init.post(`/seller/courier/remove`, data),
    restore: (data) => axios_init.post(`/seller/courier/restore`, data),
    show: (data) => axios_init.post(`/seller/courier/show`, data),
  },
  Cars: {
    getList: (data) => axios_init.post(`/seller/car/index`, data),
    create: (data) => axios_init.post(`/seller/car/create`, data),
    update: (data) => axios_init.post(`/seller/car/update`, data),
    delete: (data) => axios_init.post(`/seller/car/remove`, data),
    restore: (data) => axios_init.post(`/seller/car/restore`, data),
    show: (data) => axios_init.post(`/seller/car/show`, data),
  },
}
