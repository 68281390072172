import { notification } from "antd"
import axios from "axios"
// API constants
export const APIUrl = `https://v3.soliqservis.uz:3443/api`

export let request = axios.create({
  baseURL: APIUrl,
  Accept: "Application/json",
  "Content-Type": "Application/json",
})
request.interceptors.request.use((config) => {
  const token = localStorage.getItem("soliqToken")

  if (token) {
    config.headers = Object.assign({
      ...config.headers,
      Authorization: `Bearer ${token}`,
      "Content-Type": "Application/json",
      "platform-id": "7d4a4c38-dd84-4902-b744-0488b80a4c01",
      "organization-id": "941ba6b5-625e-4520-9406-c58dadeac59f",
    })
  }
  return config
})

request.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    if (error?.response?.status === 401) {
      // notifications.show({
      //   message: error?.response?.data?.message || "error",
      //   color: "red",
      // })
    } else {
      notification.open({ message: error?.response?.data?.data || "error" })
    }
  }
)

export const soliqRequests = {
  Soliq: {
    login: (data) => request.post("/login-tin", data),

    getInfo: (inn) => request.get(`/np1/bytin/factura?tinOrPinfl=${inn}`),

    contract: (data) => request.post("/contract", data),
    contractCancel: (tin, data) =>
      request.post(`/contract/cancel-by-owner?tin=${tin}`, data),
    getContract: (id) => request.get(`/contract/${id}`),

    factura: (data) => request.post("/factura", data),
    getFactura: (id) => request.get(`/factura/${id}`),
    facturaCancel: (tin, data) =>
      request.post(`/factura-cancel?tin=${tin}`, data),

    empowerment: (tin, data) =>
      request.post(`/empowerment/seller?tin=${tin}`, data),
    getEmpowerment: (id) => request.get(`/empowerment/${id}`),

    bank: (mfo) => request.get(`/bank/${mfo}`),
    transport: (mfo) => request.get(`/waybill-transports?tinOrPinfl=${mfo}`),
    bytin: (mfo) => request.get(`/np1/bytin?tinOrPinfl=${mfo}`),
    region: () => request.get(`/waybill-region`),
    district: (id) => request.get(`/waybill-district?regionId=${id}`),
    maxala: (soato) => request.get(`/waybill-villages?soato=${soato}`),


    


    guid: () => request.get("/utils/guid"),
    tasnif: (tin = "", search = "") =>
      request.get(`/tasnif/product?tin=${tin}`),
  },
}
